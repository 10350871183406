import PropTypes from 'prop-types'
import React from 'react'
import { Link } from 'gatsby'
import { GatsbyImage } from 'gatsby-plugin-image'
import { Card, Columns } from 'react-bulma-components'

// Components
import ArrowLink from './link-with-arrow'

function BlogCard(props) {
  return props.jumbo ? (
    <div
      className={`jumbo-blog${
        props.hasBorderTop === true ? ' has-border-top' : ''
      }`}
    >
      <Columns className="content">
        <Columns.Column className="is-5-widescreen is-6-desktop is-4-tablet is-12-mobile">
          <Link to={`/blog/${props.article.slug}`}>
            {props.article?.featuredMedia?.gatsbyImageData ? (
              <GatsbyImage
                className="is-rounded"
                image={props.article?.featuredMedia?.gatsbyImageData}
                alt={props.article.title || 'Pocketworks'}
              />
            ) : (
              <img
                src="https://www.datocms-assets.com/20955/1579120771-dscf7651fotor.jpg?fm=jpg&w=1280&h=960&fit=clip"
                alt="Placeholder"
              />
            )}
          </Link>
        </Columns.Column>
        <Columns.Column className="is-6-widescreen is-5-desktop is-7-tablet is-12-mobile ml-3">
          <Columns className="is-vcentered">
            <Columns.Column className="is-narrow">
              <GatsbyImage
                className="image is-64x64 box is-rounded has-shadow"
                image={props.article.author.mainImage?.gatsbyImageData}
                objectFit="cover"
                transformOptions={{ fit: 'cover' }}
                alt={props.article.author.name || 'Pocketworks'}
              />
            </Columns.Column>
            <Columns.Column className="">
              <span className="pl-0 is-size-5">
                By {props.article.author.name}, {props.article.date}
              </span>
            </Columns.Column>
          </Columns>
          <Link to={`/blog/${props.article.slug}`}>
            <h3 className="is-top-marginless">{props.article.title}</h3>
          </Link>
          <p
            className="is-size-4"
            dangerouslySetInnerHTML={{ __html: props.article.excerpt }}
          />
          <p className="is-pulled-left">
            <ArrowLink
              className={`is-size-5 has-text-bold ${props.article.categories[0].slug}`}
              to={`/blog/${props.article.slug}`}
            >
              Read full article
            </ArrowLink>
          </p>
        </Columns.Column>
      </Columns>
    </div>
  ) : (
    <Card
      className={`is-equal-height is-blog ${props.article.categories[0].slug}`}
    >
      <Link to={`/blog/${props.article.slug}`}>
        {props.article?.featuredMedia?.gatsbyImageData ? (
          <div className="card-image">
            <GatsbyImage
              image={props.article?.featuredMedia?.gatsbyImageData}
              alt={props.article.title || 'Pocketworks'}
            />
          </div>
        ) : (
          <img
            src="https://www.datocms-assets.com/20955/1579120771-dscf7651fotor.jpg?fm=jpg&w=1280&h=960&fit=clip"
            alt="Placeholder"
          />
        )}
      </Link>

      <Card.Content>
        <Columns className="is-vcentered is-mobile mb-0">
          <Columns.Column className="is-narrow is-1">
            <GatsbyImage
              className="image is-32x32 box is-rounded has-shadow"
              image={props.article.author.mainImage?.gatsbyImageData}
              alt={props.article.author.name || 'Pocketworks'}
            />
          </Columns.Column>
          <Columns.Column className="has-text-left">
            <span className="pl-5 is-size-6">
              {props.article.author.name}, {props.article.date}
            </span>
          </Columns.Column>
        </Columns>
        <h3 className="is-top-marginless mt-0 pt-0">
          <Link to={`/blog/${props.article.slug}`}>{props.article.title}</Link>
        </h3>
      </Card.Content>
      <Card.Footer>
        <ArrowLink
          className={`is-size-5 card-footer-item ${props.article.categories[0].slug}`}
          to={`/blog/${props.article.slug}`}
        >
          Read full article
        </ArrowLink>
      </Card.Footer>
    </Card>
  )
}

BlogCard.propTypes = {
  article: PropTypes.object,
  hasBorderTop: PropTypes.bool,
  jumbo: PropTypes.bool,
}

export default BlogCard
